import Vue from 'vue'
import { i18n, defaultLocal } from 'src/i18n'


// Prefix url for translation
Vue.filter('url', function (value) {
    if (!value) return ''
    let prefix = i18n.locale === defaultLocal ? '' : `/${i18n.locale}`
    return prefix + value
})

// Number format
Vue.filter('number', function (value) {
    if (!value) return ''
    let number = typeof value === 'string' ? value : value.toString()
    return number.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')
})
