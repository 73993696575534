import PrismicDOM from 'prismic-dom'
import { allRoutes } from 'src/router'
import { defaultLocal, i18n } from 'src/i18n'

export default function (doc) {
    let prefix = i18n.locale === defaultLocal ? '' : `/${i18n.locale}/`
    let route = allRoutes.find(route => route.meta.prismicID === doc.id)

    if (typeof route !== 'undefined' && route.meta.id !== 'home') {
        return prefix + route.path;
    }

    return i18n.locale === defaultLocal ? '/' : prefix;
};

