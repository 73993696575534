<template>
    <app-section
        :bg="fields.bg"
        :title="fields.title"
        :image="fields.image"
        :btnReturn="true"
    >
        <div
            v-for="(section, i) in fields.body"
            :key="`section-${i}`"
            class="l-section__subsection"
            v-view.reveal="1"
        >
            <div class="grid">
                <h2
                    v-if="section.primary.headline"
                    class="l-section__title | t-t2 --underline"
                >
                    <anim-text :text="section.primary.headline" />
                </h2>

                <div class="grid__row">
                    <div
                        v-for="(item, j) in section.items"
                        :key="`col-${i}-${j}`"
                        class="l-section__col | w-12/12 w-6/12@sm"
                        v-view.reveal=".8 + j * .2"
                    >

                        <img
                            v-if="item.asset.url"
                            :src="item.asset.url"
                            :alt="item.asset.name"
                            class="u-img"
                        />

                        <prismic-rich-text :field="item.desc" />

                        <block-links
                            v-if="item.link.url || item.link.id"
                            :links="[{ link: item.link, label: item.link_label }]"
                        />

                        <btn
                            v-if="item.video"
                            iconBefore="play"
                            :label="$t('btn.playVideo')"
                            @click.native="$store.dispatch('updateModal', item.video)"
                        />

                    </div>
                </div>
            </div>
        </div>
    </app-section>
</template>

<script>
import AppSection from 'layout/AppSection';
import AnimText from 'objects/AnimText';
import BlockLinks from 'objects/BlockLinks';
import PrismicRichText from 'objects/PrismicRichText';
import Btn from 'components/Btn';

export default {
    name: 'Content',
    components: {
        Btn,
        AppSection,
        AnimText,
        BlockLinks,
        PrismicRichText
    },
    computed: {
        fields() {
            return this.$store.state.page
        }
    }
};
</script>

<style lang="scss">

</style>
