<template>
    <div
        class="c-banner"
        :class="{ 'is-hidden' : isHidden }"
    >
        <div class="grid">
            <btn
                iconAfter="plus"
                class="c-banner__close"
                @click.native="close()"
            />
            <div class="c-banner__inner">
                <accordion :triggerLabel="$t('btn.info')">
                    <template v-slot:title>
                        <icon :icon="`covid19-${$i18n.locale}`" />
                        <h3
                            class="c-accordion__title | t-t3"
                            v-html="fund"
                        ></h3>

                        <img
                            :src="require('assets/img/layout/deco-covid.png')"
                            :alt="title"
                            class="c-banner__deco"
                        >
                    </template>

                    <div class="grid__row --justify-center">
                        <div class="w-12/12 w-8/12@sm w-6/12@md w-5/12@lg">
                            <h3
                                v-if="typeof title !== 'undefined'"
                                class="c-banner__title | t-t2"
                            >
                                {{ title }}
                            </h3>

                            <prismic-rich-text
                                v-if="typeof desc !== 'undefined'"
                                :field="desc"
                                class="c-banner__desc"
                            />

                            <btn
                                type="boxed"
                                tag="router-link"
                                :href="'/covid19'|url"
                                :label="$t('btn.eligibility')"
                                class="c-banner__btn"
                            />
                        </div>
                    </div>
                </accordion>
            </div>
        </div>
    </div>
</template>

<script>

import Icon from 'objects/Icon'
import PrismicRichText from 'objects/PrismicRichText';
import Btn from 'components/Btn'
import Accordion from 'components/Accordion';

export default {
    name: 'Banner',
    components: {
        Icon,
        PrismicRichText,
        Btn,
        Accordion,
    },
    data: () => ({
        isHidden: false,
    }),
    props: {
        title: {
            type: String,
        },
        desc: {
            type: String | Array,
        },
        fund: {
            type: String | Array,
        }
    },
    data: () => ({
        isHidden: false,
    }),
    methods: {
        close() {
            this.isHidden = true

            setTimeout(() => {
                this.$store.dispatch('hideBanner')
            }, 1000);
        }
    }
};

</script>

<style lang="scss">

.c-banner {
    z-index: 50;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    .c-accordion {
        border-top: 0;

        &.is-open {

            .c-banner__deco {
                transform: translate(100%, 0) scale(1.2);
            }
        }
    }

    .c-accordion__header {
        position: static;
        padding: .75em 1em;

        &:after {
            display: none;
        }
    }

    .c-accordion__title {
        display: none;
    }

    &.is-hidden {
        transform: translate(0, 100%);
        transition: transform .8s $in-out-circ;

        .c-banner__close {
            transform: scale(0);
            transition: transform .3s $in-quad;
        }
    }

    @media #{md("sm", "max")} {

        .c-accordion__btn {
            display: none;
        }

        .c-accordion__header {
            padding-top: 1em;
            padding-bottom: 1em;
            justify-content: center;
            pointer-events: none;
        }

        .c-accordion__container {
            height: auto;
            opacity: 1;
        }

        .c-accordion__content {
            padding: 0 1em 1em;
            text-align: center;
            pointer-events: initial;
        }
    }
}

.c-banner__close {
    z-index: 1;
    position: absolute;
    top: -1em;
    left: 0;
    font-size: .9rem;

    .o-round {
        --color-bg: #{$color-blue};
        --color-border: #{$color-blue};

        &:hover:before {
            --color-bg: #{$color-blue};
        }
    }

    .o-icon--plus {
        transform: rotate(45deg);
    }
}

.c-banner__inner {
    background-color: $color-light;
    box-shadow: $shadow;
}

.c-banner__title {
    margin-bottom: .75em;

    @media #{md("sm", "max")} {
        display: none;
    }
}

.c-banner__desc {
    margin-bottom: 2em;

    @media #{md("sm", "max")} {
        display: none;
    }
}

.c-banner__btn {

    @media #{md("sm")} {
        margin-bottom: 2em;
    }
}

.c-banner__deco {
    position: absolute;
    bottom: 0;
    right: 11em;
    display: block;
    @include img(7em);
    pointer-events: none;
    transform-origin: 50% 100%;
    transition: transform 1s $in-out-quint;

    @media #{md("sm", "max")} {
        display: none;
    }

    @media #{md("lg")} {
        width: 8em;
    }
}

</style>
