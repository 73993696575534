<template>
    <div
        id="app"
        class="l-wrapper"
        :class="{ 'is-loading' : isLoading }"
    >
        <app-header />
        <loader ref="loader" />

        <modal />

        <main class="l-main" ref="main">
            <router-view v-if="!isLoading" />
        </main>

        <app-footer v-if="!isLoading" />
    </div>
</template>

<script>
import 'assets/scss/styles.scss';

import AppHeader from 'layout/AppHeader';
import AppFooter from 'layout/AppFooter';
import Loader from 'objects/Loader';
import Modal from 'components/Modal';

import { detect } from 'detect-browser';
const browser = detect();

export default {
    name: 'App',
    components: {
        AppHeader,
        AppFooter,
        Loader,
        Modal,
    },
    computed: {
        isLoading() {
            return this.$store.state.loading
        }
    },
    metaInfo() {
        return {
            title: this.$t('meta.title'),
            meta: [
                // Description
                {
                    name: 'description',
                    content: this.$t('meta.description')
                },

                // Author
                {
                    name: 'author',
                    content: 'MamboMambo'
                },

                // Facebook
                {
                    property: 'og:title',
                    content: this.$t('meta.title')
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:url',
                    content: process.env.VUE_APP_SITE_URL
                },
                {
                    property: 'og:image',
                    content: `${process.env.VUE_APP_SITE_URL}/og/image.jpg`
                },
                {
                    property: 'og:image:type',
                    content: 'image/jpeg'
                },
                {
                    property: 'og:site_name',
                    content: this.$t('sitename')
                },
                {
                    property: 'og:description',
                    content: this.$t('meta.description')
                },

                // Twitter
                {
                    name: 'twitter:card',
                    content: 'summary'
                },
                {
                    name: 'twitter:site',
                    content: '@mambomambo'
                },
                {
                    name: 'twitter:creator',
                    content: '@mambomambo'
                },
                {
                    name: 'twitter:image',
                    content: `${process.env.VUE_APP_SITE_URL}/og/image.jpg`
                },
            ]
        }
    },
    created() {
        // Browser and OS classes
        document.documentElement.classList.add(`is-${browser.os.replace(' ', '-').toLowerCase()}`)
        document.documentElement.classList.add(`is-${browser.name}`)

        // HTML Lang
        this.setHtmlLang()

        this.$store.dispatch('firstLoad')
    },
    methods: {
        setHtmlLang() {
            document.documentElement.setAttribute('lang', this.$i18n.locale)
        }
    },
    watch: {
        $route(to, from) {
            this.setHtmlLang()
        }
    }
}
</script>

<style lang="scss">
</style>
