<template>
    <transition
        name="anim-slide"
        @before-enter="setVideoSize"
        @after-enter="show"
        @before-leave="hide"
    >
        <div
            v-if="video"
            class="c-modal"
        >
            <span class="c-modal__bg"></span>
            <video-player
                :video="video"
                ref="player"
                class="c-modal__video"
            />
        </div>
    </transition>
</template>

<script>

import VideoPlayer from 'objects/VideoPlayer'
import Variables from 'src/mixins/variables';

import debounce from 'lodash/debounce';

export default {
    name: 'Modal',
    components: {
        VideoPlayer
    },
    mixins: [ Variables ],
    props: {
        links: {
            type: Array,
        },
    },
    data: () => ({
        playerOpts: {
            autoplay: false,
            controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']
        },
        currentScroll: 0,
        resize: null
    }),
    computed: {
        video() {
            return this.$store.state.video
        }
    },
    methods: {
        show() {
            this.currentScroll = window.scrollY
            document.documentElement.style.overflowY = 'hidden';

            this.$refs.player.play()
        },
        hide() {
            // Add previous scroll
            document.documentElement.style.overflowY = 'scroll';
            window.scrollTo(0, this.currentScroll)
        },
        setVideoSize() {
            if(!this.video) return;

            const ratio = this.W.w/this.W.h

            if(ratio > 16/9) {
                TweenLite.set(this.$refs.player.$el, {
                    width: this.W.h * .8 * 16/9,
                    height: this.W.h * .8
                })
            } else {
                TweenLite.set(this.$refs.player.$el, {
                    width: this.W.w * .8,
                    height: this.W.w * .8 * 9/16
                })
            }

            this.$refs.player.$el.classList.add('is-set')
        },
    },
    mounted() {
        window.addEventListener('resize', this.resize = debounce(this.setVideoSize, 100));
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resize);
    }
};
</script>

<style lang="scss">

.c-modal {
    z-index: 150;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:not(.anim-slide-enter-active) .c-modal__video {
        opacity: 1;
    }
}

.c-modal__bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $color-light;
}

.c-modal__video {
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    width: auto;

    opacity: 0;
    overflow: hidden;
    transform: translate(-50%, -50%);

    @media #{md("sm")} {
        //top: calc(50% + 5.5em/2);
    }
}

.anim-slide-enter-active {
    transition: all 1.2s;

    .c-modal__bg {
        transition: transform .8s $in-out-quint;
    }

    .c-modal__video {
        opacity: 1;
        //transform: translate(-50%, -50%);
        transition: opacity .4s ease-out .6s, transform .4s $out-quad .6s;
    }
}

.anim-slide-leave-active {
    transition: all .8s;

    .c-modal__bg {
        transition: transform .8s $in-out-expo;
    }

    .c-modal__video {
        transition: opacity .4s ease-in, transform .4s $in-quad;
    }
}

.anim-slide-enter, .anim-slide-leave-to {

    .c-modal__bg {
        transform: translate(0, -105%) skew(0, 3deg);
    }

    .c-modal__video {
        opacity: 0;
        transform: translate(-50%, calc(-50% - 1em));
    }
}

</style>
