<template>
    <span
        class="o-loader"
        ref="loader"
    >
        <span class="o-loader__counter">{{ counter.value }}</span>
    </span>
</template>


<script>
import Variables from 'src/mixins/variables';

export default {
    name: 'Loader',
    mixins: [ Variables ],
    data: () => ({
        firstload: true,
        counter: {
            value: 0
        },
    }),
    methods: {
        animateIn(next) {
            TweenLite.to(this.$refs.loader, .4, {
                opacity: 1,
                ease: Power2.easeIn,
                onComplete: () => {
                    // next()
                    this.animateOut()
                }
            })
        },
        animateOut() {
            TweenLite.to(this.$refs.loader, .4, {
                opacity: 0,
                onComplete: () => {
                    this.isLoading = false
                    // EventBus.$emit('loader-complete');
                }
            })
        },
    },
    mounted() {

        /*
        TweenLite.to(this.counter, 4, {
            value: 1000,
            ease: Expo.easeInOut,
            onUpdate: () => {
                this.counter.value = Math.round(this.counter.value)
            }
        })
        */

        // Loader animation start
//        EventBus.$on('loader-start', () => {
//            this.animateIn(next)
//        })

        // this.animateOut()
    },
}
</script>

<style lang="scss">

    .o-loader {
        z-index: 200;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        padding-right: 50%;
        pointer-events: none;
        //opacity: 0;
        background-color: $color-light;

        display: none;

        .is-loading & {
            pointer-events: initial;
        }
    }

    .o-loader__counter {
        @include responsive-type(32, 256, 320, 1960);
        font-weight: 700;
    }

</style>
