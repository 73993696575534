import {
    CSSPlugin,
    TweenLite,
    ScrollToPlugin,
} from 'gsap/all';

// Ensure modules don't get dropped by tree-shaking
const gsap = [
    CSSPlugin,
    TweenLite,
    ScrollToPlugin,
];

export default gsap;
