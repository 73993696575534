<template>
    <div>
        <app-section
            bg="grey"
            :btnReturn="true"
            class="p-hero"
        >
            <template v-slot:header>
                <div class="grid__row --justify-between@md">
                    <div class="l-section__col | w-12/12 w-10/12@sm w-6/12@md w-5/12@lg" v-view.reveal="1.2">
                        <h1 class="p-hero__title | t-t1-bold">
                            <anim-text :text="fields.title" />
                        </h1>

                        <btn
                            v-if="fields.video"
                            iconBefore="play"
                            :label="$t('btn.playVideo')"
                            @click.native="$store.dispatch('updateModal', fields.video)"
                        />
                    </div>

                    <div class="w-12/12 w-10/12@xs --justify-self-center@xs w-6/12@sm --justify-self-end@md">
                        <div class="p-hero__animation" ref="heroAsset" v-view="animateHero"></div>
                        <!--
                        <img
                            :src="require('assets/img/layout/home-hero.png')"
                            alt="Propulser"
                            class="p-hero__img"
                        >
                        -->
                    </div>
                </div>

                <!--
                <btn
                    tag="router-link"
                    type="boxed"
                    :href="'/criteres-admissibilite'|url"
                    :label="$t('btn.eligibility')"
                    class="p-hero__btn"
                />
                -->
            </template>


            <div class="p-intro" v-view.reveal="1">
                <div>
                    <span class="p-intro__bg --bg-blue"></span>
                    <div class="p-intro__content | grid">
                        <div
                            v-if="fields.video"
                            class="grid__row --justify-between@md"
                        >
                            <div
                                v-if="fields.video_title"
                                class="w-12/12 w-8/12@sm w-5/12@md w-4/12@lg"
                            >
                                <h2 class="l-section__title | t-t2">
                                    <anim-text :text="fields.video_title" />
                                </h2>
                            </div>
                            <div class="w-12/12 w-10/12@sm w-6/12@md">
                                <video-player
                                    :video="fields.video"
                                    :icon="true"
                                    class="p-intro__video"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-intro__content | grid">
                    <div class="grid__row --justify-between@md">
                        <div class="w-12/12 w-10/12@sm w-6/12@md w-5/12@lg">
                            <h2
                                v-if="fields.intro_headline"
                                class="l-section__title | t-t1"
                            >
                                <anim-text :text="fields.intro_headline" />
                            </h2>
                        </div>
                        <div class="w-12/12 w-10/12@sm w-6/12@md">
                            <div
                                class="l-section__col"
                                 v-view.reveal="1"
                            >
                                <h3
                                    v-if="fields.intro_title"
                                    class="l-section__title | t-t2 --underline"
                                >
                                    <anim-text :text="fields.intro_title" :delay=".4" />
                                </h3>

                                <prismic-rich-text :field="fields.intro_desc" class="t-t3" />

                                <block-links :links="fields.intro_links" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-intro__animation" ref="introAsset" v-view="animateIntro"></div>

            <!--
            <img
                :src="require('assets/img/layout/home-intro.png')"
                alt="Propulser"
                class="p-intro__img"
            >
            -->

        </app-section>

        <app-section
            bg="dark"
        >
            <template v-slot:header>

                <div class="p-graph">
                    <grid :columns="12" class="u-dn@sm-max" />
                    <shape
                        v-for="(fund, i) in fields.funds"
                        :key="`graph-${i}`"
                        :shape="i === 1 ? 'circle' : 'rectangle'"
                        :color="fund.color"
                        :data-item="i"
                        v-view="graphParallax"
                    >
                        <span
                            v-if="fund.name"
                            class="t-t3-bold"
                        >
                            {{ fund.name }}
                        </span>
                    </shape>
                </div>

                <div class="grid__row" v-view.reveal="1.2">
                    <div
                        v-for="(fund, i) in fields.funds"
                        :key="`fund-${i}`"
                        class="l-section__col | p-fund | w-12/12 w-4/12@sm"
                        v-view.reveal="1.2"
                    >
                        <shape
                            :shape="i === 1 ? 'circle' : 'rectangle'"
                            :color="fund.color"
                            size="xs"
                        />
                        <h3
                            v-if="fund.name"
                            class="p-fund__title | t-t3-bold"
                        >
                            <anim-text :text="fund.name" />
                        </h3>
                        <prismic-rich-text :field="fund.desc" class="t-t4" />
                    </div>
                </div>

            </template>

            <div class="p-steps__deco">
                <shape shape="line" color="red" v-view="parallax" />
                <shape shape="square" color="blue" v-view="parallax"/>
                <shape shape="circle" color="yellow" v-view="parallax"/>
            </div>

            <div class="grid">
                <div class="grid__row --justify-between@md" v-view.reveal="1">
                    <div class="w-12/12 w-10/12@sm w-6/12@md w-5/12@lg">
                        <h2
                            v-if="fields.steps_headline"
                            class="l-section__title | t-t1"
                        >
                            <anim-text :text="fields.steps_headline" />
                        </h2>
                    </div>

                    <div class="w-12/12 w-10/12@sm w-6/12@md">
                        <div
                            class="l-section__col"
                             v-view.reveal="1"
                        >
                            <h3
                                v-if="fields.steps_title"
                                class="l-section__title | t-t2 --underline"
                            >
                                <anim-text :text="fields.steps_title" :delay=".4" />
                            </h3>

                            <prismic-rich-text :field="fields.steps_desc" class="t-t3" />
                        </div>
                    </div>
                </div>

                <div id="steps" class="p-steps">
                    <accordion
                        v-for="(step, i) in fields.body"
                        :key="`step-${i + 1}`"
                    >
                        <template v-slot:title>
                            <span class="c-accordion__number | t-t1">{{i + 1}}.</span>
                            <h3
                                v-if="step.primary.label"
                                class="c-accordion__title | t-t3"
                                v-html="step.primary.label"
                            ></h3>
                        </template>

                        <div class="p-steps__step">
                            <prismic-rich-text :field="step.primary.desc" />
                            <prismic-rich-text :field="step.primary.info" class="t-info" />

                            <block-links
                                v-if="step.primary.btn_link1.url || step.primary.btn_link2.url"
                                :links="[{ link: step.primary.btn_link1, label: step.primary.btn_label1 }, { link: step.primary.btn_link2, label: step.primary.btn_label2 }]"
                            />

                            <step-form :items="step.items" />
                        </div>
                    </accordion>
                </div>
            </div>
        </app-section>

        <app-section
            bg="grey"
            class="p-project"
            :class="{ 'u-dn' : !hasProjects }"
        >
            <div class="grid">
                <div class="grid__row">
                    <div class="w-12/12 w-4/12@sm w-3/12@lg">
                        <div
                            class="p-project__heading | l-section__col"
                            :class="{ 'is-hidden' : !firstSlide }"
                             v-view.reveal="1"
                        >
                            <h2
                                v-if="fields.projects_title"
                                class="t-t1"
                            >
                                <anim-text :text="fields.projects_title" />
                            </h2>
                            <prismic-rich-text :field="fields.projects_desc" class="t-t4" />
                            <btn
                                v-if="fields.projects_btn_link.url"
                                tag="a"
                                type="stroke"
                                :href="fields.projects_btn_link.url"
                                :target="fields.projects_btn_link.target"
                                :label="fields.projects_btn_label ? fields.projects_btn_label : fields.projects_btn_link.url"
                            />
                        </div>
                    </div>

                    <div class="w-12/12 w-8/12@sm w-9/12@lg">
                        <div
                            class="p-project__slider"
                            :key="`slider-projects-${projects.length}`"
                        >

                            <span class="p-project__nav">
                                <button
                                    :class="{ 'is-disabled' : firstSlide }"
                                    @click="$refs.flickity.previous()"
                                    :aria-label="$t('aria.sliderPrev')"
                                >
                                    <round icon="arrow-left" size="md" />
                                </button>
                                <button
                                    :class="{ 'is-disabled' : lastSlide }"
                                    @click="$refs.flickity.next()"
                                    :aria-label="$t('aria.sliderNext')"
                                >
                                    <round icon="arrow-right" size="md" />
                                </button>
                            </span>

                            <flickity
                                v-if="hasProjects"
                                ref="flickity"
                                :options="flickityOpts"
                                class="p-projects__slider"
                            >
                                <card
                                    v-for="(project, i) in projects"
                                    :key="`project-${i}`"
                                    :data="project"
                                />
                            </flickity>
                        </div>
                    </div>
                </div>
            </div>
        </app-section>

        <banner
            v-if="fields.covid19_show && $store.state.showBanner"
            :title="fields.covid19_title"
            :desc="fields.covid19_desc"
        />

    </div>
</template>

<script>
import Card from 'objects/Card';
import Icon from 'objects/Icon';
import Round from 'objects/Round';
import Grid from 'objects/Grid';
import Shape from 'objects/Shape'
import AnimText from 'objects/AnimText'
import BlockLinks from 'objects/BlockLinks';
import VideoPlayer from 'objects/VideoPlayer'
import PrismicRichText from 'objects/PrismicRichText';
import Btn from 'components/Btn';
import StepForm from 'components/StepForm';
import Accordion from 'components/Accordion';
import Banner from 'components/Banner';
import AppSection from 'layout/AppSection';

import Variables from 'mixins/variables';

import Flickity from 'vue-flickity';
import lottie from 'lottie-web'

export default {
    name: 'Home',
    components: {
        Card,
        Icon,
        Round,
        Grid,
        Shape,
        AnimText,
        BlockLinks,
        VideoPlayer,
        PrismicRichText,
        Btn,
        StepForm,
        Accordion,
        Banner,
        AppSection,
        Flickity
    },
    mixins: [ Variables ],
    data: () => ({
        hero: {},
        intro: {},
        flickityOpts: {
            pageDots: false,
            cellAlign: 'left',
            contain: true,
        },
        firstSlide: true,
        lastSlide: false,
        hasProjects: false
    }),
    computed: {
        fields() {
            return this.$store.state.page
        },
        projects() {
            const projects = this.$store.state.projects
            this.hasProjects = projects.length > 0
            return projects
        },
    },
    mounted() {

        // Hero Animation
        this.hero.animation = lottie.loadAnimation({
            container: this.$refs.heroAsset,
            renderer: 'svg',
            autoplay: false,
            animationData: require(`assets/animations/hero.json`)
        });
        this.hero.frames = this.hero.animation.getDuration(true)

        // Intro Animation
        this.intro.animation = lottie.loadAnimation({
            container: this.$refs.introAsset,
            renderer: 'svg',
            autoplay: false,
            animationData: require(`assets/animations/intro.json`)
        });
        this.intro.frames = this.intro.animation.getDuration(true)

    },
    methods: {
        flickityChange(i) {
            this.firstSlide = i === 0
            this.lastSlide = i === this.projects.length - 1
        },
        animateHero(e) {
            let frame = this.W.w < 992 ? (0.85 - e.percent.center.y) * this.hero.frames : (0.5 - e.percent.center.y) * this.hero.frames * 2
            frame = frame > this.hero.frames ? this.hero.frames : frame
            frame = frame < 0 ? 0 : Math.round(frame)
            this.hero.animation.goToAndStop(frame, true)
        },
        animateIntro(e) {
            let frame = (1 - e.percent.center.y) * this.intro.frames/1.25
            frame = frame % this.intro.frames
            frame = frame < 0 ? 0 : Math.round(frame)
            this.intro.animation.goToAndStop(frame, true)
        },
        parallax(e) {
            let x, y;
            const classlist = e.target.classList
            if(classlist.contains('o-shape--square')) {
                x = e.percent.top * this.W.w/20
                y = e.percent.top * this.W.h/20
            } else if(classlist.contains('o-shape--line')) {
                x = -e.percent.top * this.W.w/20
                y = -e.percent.top * this.W.h/20
            } else {
                x = -e.percent.top * this.W.w/30
                y = e.percent.top * this.W.h/10
            }

            TweenLite.to(e.target, .2, { x, y })
        },
        graphParallax(e) {
            let x = 0, y = 0, rotation = 0, scale = 1;
            const item = e.target.dataset.item
            if(item == 0) {
                //x = -e.percent.top * this.W.h/40
                y = -e.percent.top * this.W.h/20
                rotation = -4* (1 - e.percent.top)
                scale = 1 - e.percent.top/50
            } else if(item == 1) {
                x = -e.percent.top * this.W.w/20
                y = e.percent.top * this.W.h/20
                scale = 1 - e.percent.top/5
            } else {
                //x = e.percent.top * this.W.h/40
                y = e.percent.top * this.W.h/20
                rotation = 4 * (1 - e.percent.top)
                scale = 1 - e.percent.top/50
            }

            TweenLite.to(e.target, .2, { x, y, rotation, scale })
        }
    },
    watch: {
        hasProjects(val) {
            setTimeout(() => {
                this.$refs.flickity.resize()
                this.$refs.flickity.on('change', this.flickityChange)

                /*
                this.$refs.flickity.on('dragEnd', this.flickityChange)
                this.$refs.flickity.on('dragMove', () => {
                    this.firstSlide = false
                })
                */
            }, 10);
        }
    }
};
</script>

<style lang="scss">


/*====================================
=            Hero section            =
====================================*/

.p-hero {

    .l-section__col {
        z-index: 2;
    }

    @media #{md("sm", "max")} {

        .l-section__header {
            max-height: calc(100vh - #{$header-height});
        }
    }

    @media #{md("md")} {

        .l-section__header {
            z-index: 2;
        }
    }
}

.p-hero__animation {
    z-index: 1;
    margin-top: -20%;
    margin-bottom: calc(-10vh);

    .js-reveal & {
        opacity: 0;
        transform: scale(.98, .95);
        transform-origin: 50% 80%;
    }

    .js-reveal.is-visible & {
        opacity: 1;
        transform: scale(1);
        transition: all .4s ease-out .8s;
    }

    @media #{md("sm", "max")} {
        text-align: center;

        svg {
            width: auto !important;
            max-width: 100%;
            height: auto !important;
            max-height: 65vh;
        }
    }

    @media #{md("xs", "max")} {

        svg {
            max-height: 60vh;
        }
    }

    @media #{md("md")} {
        z-index: 100;
        margin-top: calc(-#{$header-height} - 10vh - 10%);

        svg {
            width: auto !important;
            height: auto !important;
            max-width: 100%;
            max-height: 100vh;
        }
    }
}

/*
.p-hero__img {
    @include img;
    margin-top: -20%;
    margin-bottom: calc(-10vh);

    .js-reveal & {
        opacity: 0;
        transform: scale(.98, .95);
        transform-origin: 50% 80%;
    }

    .js-reveal.is-visible & {
        opacity: 1;
        transform: scale(1);
        transition: all .4s ease-out .8s;
    }

    @media #{md("sm", "max")} {
        width: auto;
        max-height: 65vh;
        margin-right: auto;
        margin-left: auto;
    }

    @media #{md("xs", "max")} {
        max-height: 60vh;
    }

    @media #{md("md")} {
        z-index: 100;
        margin-top: calc(-#{$header-height} - 10vh - 10%);
        width: auto;
        max-width: 100%;
        max-height: 100vh;
    }
}
*/

/*
.p-hero__btn {
    position: absolute;
    bottom: calc(-5vh - 4em);
    right: 0;

    @media #{md("sm", "max")} {
        bottom: -5vh;
        right: 50%;
        width: 100%;
        max-width: 18em;
        text-align: center;
        transform: translate(50%, 0);
    }
}
*/


/*=====================================
=            Intro section            =
=====================================*/


.p-intro {
}

.p-intro__bg {
    --skew: -3deg;

    position: absolute;
    top: -15vh;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--color-bg);
    transform: skew(0, var(--skew));

    .js-reveal & {
        background-color: $color-light;
        transform: translate(0, -5vh) skew(0, var(--skew));
    }

    .js-reveal.is-visible & {
        background-color: var(--color-bg);
        transform: translate(0) skew(0, var(--skew));
        transition: all .6s ease-in;
    }

    @media #{md("xl")} {
        --skew: -2deg;
    }
}

.p-intro__content {
    z-index: 2;
}

.p-intro__animation {
    z-index: 1;
    width: 90%;


    @media #{md("md", 'max')} {
        max-width: 22em;
        margin-top: -10vh;
        margin-bottom: -25vh;
    }

    @media #{md("md")} {
        position: absolute;
        left: 0;
        bottom: -15vh;
        width: 45%;
        max-width: $layout-main--max-width/2
    }
}

.p-intro__video {
    margin-bottom: 4em;

    @media #{md("sm", "max")} {
        margin-top: 1.5em;
    }

    @media #{md("md")} {
        margin-bottom: 6em;
    }
}

/*
.p-intro__img {
    z-index: 1;
    @include img(90%);

    @media #{md("md", 'max')} {
        max-width: 22em;
        margin-top: -10vh;
        margin-bottom: -25vh;
    }

    @media #{md("md")} {
        position: absolute;
        left: 0;
        bottom: -15vh;
        width: 45%;
        max-width: $layout-main--max-width/2
    }
}
*/


/*=====================================
=            Funds section            =
=====================================*/

.p-graph {
    display: flex;
    margin-top: 10vh;
    margin-bottom: 15vw;

    .o-shape {
        align-items: flex-start;
        justify-content: flex-start;

        &--rectangle {
            --width: 96vw;
            --rotate: 3.5deg;
            padding-top: calc(var(--width)/10);
            padding-left: calc(var(--width)/15);
            transform: rotate(var(--rotate));

            .o-shape__inner {
                transform: rotate(calc(-1 * var(--rotate)));
            }

            &:last-child {
                --rotate: -3.5deg;
            }
        }

        &--circle {
            --width: 50vw;
            position: absolute;
            right: 5%;
        }
    }

    .o-shape__inner {
        display: inline-block;
        width: 60%;
        padding-bottom: 1em;
        font-weight: 700;
        border-bottom: $border;
    }

    @media #{md("sm", "max")} {
        flex-direction: column;
        align-items: center;

        .o-shape {

            &--rectangle {
                height: calc(var(--width) * .7);

                &:last-child {
                    top: calc(var(--width)/17);
                }

                .o-shape__inner {
                    transform: rotate(var(--rotate));
                }
            }

            &--circle {
                top: 13.7%;
            }
        }
    }

    @media #{md("sm")} {
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10vh;

        .o-shape {

            &--rectangle {
                --width: 46.7%;
                --rotate: -3.5deg;
                height: 0;
                padding-top: 45%;
                transform-origin: 0 0;

                &:last-child {
                    --width: 47.5%;
                    --rotate: 2deg;
                    transform-origin: 100% 0;
                }

                .o-shape__inner {
                    position: absolute;
                    top: calc(var(--width)/5);
                    left: calc(var(--width)/7);
                }
            }

            &--circle {
                --width: 35vw;
                right: 51%;
                bottom: 3.32%;
                align-items: center;
            }
        }
    }

    @media #{md("xl")} {

        .o-shape {

            &--circle {
                --width: 30em;
            }
        }
    }
}

.p-fund {

    &:first-child .p-fund__shape {
        transform: rotate(-5deg);
    }

    &:last-child .p-fund__shape {
        transform: rotate(5deg);
    }

    @media #{md("sm", "max")} {

        &:nth-child(n+2) {
            margin-top: 2em;
        }
    }

    @media #{md("md")} {

        &.js-reveal {

            &:nth-child(3n+2) {
                --sectionDelay: .2s;
            }

            &:nth-child(3n) {
                --sectionDelay: .4s;
            }
        }
    }
}

.p-fund__title {
    margin-top: .5em !important;
}


/*=====================================
=            Steps section            =
=====================================*/

.p-steps {
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.p-steps__deco {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .o-shape {
        position: absolute;

        &--line {
            top: -10%;
            left: -10%;
            transform: rotate(60deg);
        }

        &--square {
            top: 7%;
            left: 0;
            transform: rotate(20deg) translate(-50%, 0);
        }

        &--circle {
            top: 4.5%;
            left: 5em;
        }
    }

    @media #{md("sm", "max")} {
        overflow: hidden;
    }

    @media #{md("sm")} {

        .o-shape {

            &--line {
                --width: 15vw;
                top: -8vw;
                left: -6vw;
                transform-origin: 0 100%;
            }

            &--square {
                --width: 18vw;
                top: 24.2vw;
                left: -5vw;
                transform: rotate(20deg);
            }

            &--circle {
                --width: 18vw;
                top: 18vw;
                left: 15.5vw;
            }
        }
    }
}

.p-steps__step {
    max-width: 45em;
    margin-right: auto;
    margin-left: auto;
    padding-top: 5vh;
    padding-bottom: 5vh;
}


/*========================================
=            Projects section            =
========================================*/

.p-project {
    overflow: hidden;

    .c-btn__label {
        white-space: nowrap;
    }
}

.p-project__heading {
    transform-origin: 50% 100%;
    transition: all .3s ease-out .35s;

    @media #{md("sm")} {

        &.is-hidden {
            opacity: 0;
            transform: scale(.98);
            transition: all .2s 0s;
        }
    }
}

.p-project__slider {

    .o-card {
        width: 90%;
        max-width: 22em;
        margin-right: .5em;
        margin-left: .5em;
    }

    .flickity-viewport {
        overflow: visible;
    }

    .flickity-prev-next-button {
        display: none;
    }
}

.p-project__nav {
    display: flex;
    justify-content: flex-end;
    text-align: right;

    button {
        display: block;
        cursor: pointer;

        &:nth-child(2) {
            margin-left: 1em;
        }

        &.is-disabled {
            pointer-events: none;
            opacity: .5;
        }

        &:hover {

            .o-round:before {
                --color-bg: transparent;
                border-radius: 0;
                border-width: .2em;
            }

            &:first-child .o-round:before {
                transform: rotate(-135deg) scale(.95);
            }

            &:last-child .o-round:before {
                transform: rotate(135deg) scale(.95);
            }
        }

        &:active {

            &:first-child .o-round:before {
                border-radius: 50%;
                transform: rotate(-180deg) scale(1.1);
                transform: all .1s;
            }

            &:last-child .o-round:before {
                border-radius: 50%;
                transform: rotate(180deg) scale(1.1);
                transform: all .1s;
            }
        }
    }

    @media #{md("sm", "max")} {
        top: -2.5em;
    }

    @media #{md("sm")} {
        margin-top: 5vh;
        margin-bottom: 2em;
    }
}



</style>
