<template>
    <div
        v-if="items.length > 0"
        class="o-step-form"
    >
        <div
            v-for="(item, i) in items"
            :key="`item-${i}`"
            class="o-step-form__item"
        >
            <prismic-rich-text :field="item.question" />
            <prismic-rich-text :field="item.info" class="t-info" />

            <div class="c-form__fieldset">
                <div class="c-form__el">
                    <input
                        :id="`item-${i}-yes`"
                        :name="`item-${i}`"
                        :value="true"
                        type="radio"
                        v-model="checked[i]"
                    >
                    <label
                        :for="`item-${i}-yes`"
                    >
                        {{ $t('stepform.yes' )}}
                    </label>
                </div>
                <div class="c-form__el">
                    <input
                        :id="`item-${i}-no`"
                        :name="`item-${i}`"
                        :value="false"
                        type="radio"
                        v-model="checked[i]"
                    >
                    <label
                        :for="`item-${i}-no`"
                    >
                        {{ $t('stepform.no' )}}
                    </label>
                </div>
            </div>
        </div>

        <btn
            tag="a"
            target="_blank"
            type="boxed"
            :label="$t('stepform.btn.submit')"
            :disabled="!allChecked && !hasNoAnswer"
            :hidden="hasNoAnswer"
            href="http://bit.ly/2LeYWHc"
            class="o-step-form__submit"
        />

        <div v-if="hasNoAnswer">
            <p class="t-info">
                {{ $t('stepform.info') }}
            </p>
            <div class="o-step-form__btns">
                <btn
                    tag="a"
                    target="_blank"
                    href="http://bit.ly/2LeYWHc"
                    type="boxed"
                    :label="$t('stepform.btn.submitAnyway')"
                />

                <span class="o-step-form__or">{{ $t('stepform.or') }}</span>

                <btn
                    tag="a"
                    target="_blank"
                    href="http://bit.ly/37XKc9m"
                    type="boxed"
                    :label="$t('stepform.btn.submitRuche')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Btn from 'components/Btn';
import PrismicRichText from 'objects/PrismicRichText';

export default {
    name: 'StepForm',
    components: {
        Btn,
        PrismicRichText,
    },
    data: () => ({
        checked: [],
        allChecked: false,
        hasNoAnswer: false
    }),
    props: {
        items: {
            type: Array,
        },
    },
    watch: {
        checked() {
            this.allChecked = this.checked.length === this.items.length && !this.checked.includes(undefined)
            this.hasNoAnswer = this.checked.some((input) => input === false)
            //console.log(this.allChecked)
        }
    }
}
</script>

<style lang="scss">

.o-step-form {}

.o-step-form__item {
    padding-top: 2em;
    padding-bottom: 2em;

    &:nth-child(n+2) {
        border-top: $border-light;
    }
}

.o-step-form__submit {
    width: 100%;
    margin-top: 1em;
    text-align: center;
}

.o-step-form__btns {
    display: flex;
    text-align: center;

    .c-btn {
        text-align: center;
    }

    @media #{md("sm", "max")} {
        flex-direction: column;

        .c-btn {
            margin-top: 1em;
            margin-bottom: 1em;
        }
    }

    @media #{md("sm")} {
        //align-items: center;

        .c-btn {
            width: calc(50% - 5em/2);
        }

        .o-step-form__or {
            display: inline-block;
            align-self: center;
            width: 5em;
        }
    }
}

// Inputs
.c-form__fieldset {
    display: inline-flex;
    width: auto;
    margin-top: 1em;
    text-align: center;
    border: $border;
}

.c-form__el {
    width: 6em;
    user-select: none;

    &:nth-child(n+2) {
        border-left: $border;
    }

    input {
        display: none;

        &:checked + label {
            color: $color-light;
            background-color: $color-dark;
        }
    }

    label {
        display: block;
        padding-top: .6em;
        padding-bottom: .6em;
        line-height: 1;
        font-weight: 700;
    }
}
</style>
