<template>
    <vue-plyr
        v-if="video"
        :options="options"
        :playsinline="true"
        ref="plyr"
        class="o-video-player"
        :class="{ 'show-controls' : isTouch }"
        @click="togglePlay()"
    >
        <div
            data-plyr-provider="vimeo"
            :data-plyr-embed-id="video"
        ></div>
        <round
            v-if="icon"
            icon="play"
            size="lg"
            @click="togglePlay()"
        />
        <div
            class="o-video-player__trigger"
            @click="togglePlay()"
        ></div>
    </vue-plyr>
</template>

<script>
import Round from 'objects/Round';

export default {
    name: 'VideoPlayer',
    components: {
        Round,
    },
    props: {
        video: {
            type: String,
            required: true
        },
        options: {
            type: Object,
            default: () => ({
                autoplay: false,
                controls: ['play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
            }),
        },
        icon: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        isTouch: ("ontouchstart" in document.documentElement)
    }),
    computed: {
        className() {
            let className = `o-shape o-shape--${this.shape} --bg-${this.color}`
            className += this.size !== null ? ` --${this.size}` : ''
            return className
        }
    },
    methods: {
        play() {
            this.$refs.plyr.player.play()
        },
        togglePlay() {
            console.log('togglePlay')
            this.$refs.plyr.player.togglePlay()
        }
    }
};
</script>

<style lang="scss">

.o-video-player {

    .plyr--video {
        background: none;

        .plyr__controls {
            padding: .5em .75em;
            background-color: $color-dark;
        }

        &.plyr--stopped,
        &:not(:hover).plyr--paused {

            .plyr__controls {
                opacity: 0;
                pointer-events: none;
                transform: translateY(100%);
            }
        }

        &.plyr--stopped {

            & + .o-round {
                display: flex;
            }
        }
    }

    .plyr__poster,
    .plyr__video-wrapper {
        background-color: $color-light;
    }

    .plyr--full-ui input[type=range] {
        color: $color-blue;
    }

    .plyr__control {
        z-index: 2;
        padding: .5em;
        border-radius: 50%;

        &:hover {
            background-color: $color-blue !important;
        }

        &.plyr__tab-focus {
            box-shadow: none;
        }
    }

    [data-plyr="play"] {
        left: -.1em;

        .icon--not-pressed {
            left: .1em;
        }
    }

    .plyr__poster {
        pointer-events: none;
    }

    .plyr__tooltip {
        background-color: $color-grey;
        border-radius: 0;
        box-shadow: none;

        &:before {
            border-top-color: $color-grey
        }
    }

    .o-round {
        position: absolute;
        top: calc(50% - var(--size)/2);
        left: calc(50% - var(--size)/2);
        pointer-events: none;
        display: none;
        transition: color .3s ease-out;
    }

    &:hover,
    &.show-controls {

        .plyr__controls {
            opacity: 1 !important;
            pointer-events: initial !important;
            transform: translateY(0) !important;
        }
    }

    &.show-controls {

        .plyr--video {
            padding-bottom: calc(3em - 1px);
            overflow: visible;
        }

        .plyr__controls {
            bottom: 0;
            height: 3em;
        }

        .o-video-player__trigger {
            bottom: 3em;
        }
    }

    &:hover {


        .o-round {
            --color-border: #{$color-dark};
            color: $color-light;

            &:before {
                --color-bg: #{$color-dark};
                border-width: .2em;
                border-radius: 0;
                transform: rotate(135deg) scale(.95);
            }
        }
    }
}

    .o-video-player__trigger {
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }

</style>
