<template>
    <a
        :href="byLocale('urls')"
        :title="data.name"
        class="o-card"
    >
        <span class="o-card__region | t-t4">
            {{ data.ruche.name }}
        </span>
        <h3 class="o-card__title | t-t3-bold">
            {{ byLocale('title') }}
        </h3>

        <span
            class="o-card__asset"
            :style="{ backgroundImage: `url('${data.image.medium}')` }"
        ></span>

        <span class="o-card__item o-card__item--contributions">
            {{ data.currentAmount|number }}&nbsp;$ {{ $t('card.collected')}} {{ data.targetAmount|number }}&nbsp;$
        </span>

        <span class="o-card__progress">
            <span
                :style="{ transform: `scaleX(${data.progress > 100 ? 1 : data.progress/100})` }"
            ></span>
        </span>

        <span
            class="o-card__item o-card__item--finAdd | findAdd"
        >
            <label class="findAdd__headline">
                <strong>{{ $t('card.finAdd.label')}}</strong>
                <span v-if="m1 && m1.amount !== data.programsAmountTotal">
                + {{ data.programsAmountTotal|number }}&nbsp;$
                </span>
            </label>
            <ul v-if="programs" class="findAdd__programs">
                <li
                    v-for="(program, i) in programs"
                    :key="`program-${i}`"
                >
                    <img :src="program.logo[locale]" :alt="program.name[locale]" class="icon"> {{ program.amount|number }}&nbsp;$
                </li>
            </ul>
        </span>

        <span class="o-card__bottom">
            <span class="o-card__item o-card__item--days">
                <icon icon="clock" />
                <span v-if="data.remainingDays > 0">
                    {{ data.remainingDays }}
                    {{ $t('card.daysleft')}}
                </span>
                <span v-else>
                    {{ $t('card.finished')}}
                </span>
            </span>

            <span class="o-card__item">{{ $t('card.progress')}} {{ data.progress }}&nbsp;%</span>
        </span>

    </a>
</template>

<script>

import Icon from 'objects/Icon'
import { i18n, currentLocal, defaultLocal } from 'src/i18n'

export default {
    name: 'Card',
    components: {
        Icon,
    },
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    data: () => ({
        partnerID: process.env.VUE_APP_PARTNER_ID
    }),
    computed: {
        locale() {
            return i18n.locale || defaultLocal
        },
        title() {
            return this.data.title[this.locale]
        },
        programs() {

            let programs = this.data.programs,
                m1Index = programs.findIndex((program) => program.programId === this.partnerID)

            // Always put M1 in 1st position
            programs = this.movePosition(programs, m1Index, 0)

            return programs
        },
        m1() {
            const m1 = this.data.programs.find((program) => program.programId === this.partnerID)
            return m1 || false
        }
    },
    methods: {
        byLocale(prop) {
            const locale = this.locale
            return this.data[prop] ? this.data[prop][locale] : ''
        },
        movePosition(stack, oldIndex, newIndex) {

            if (oldIndex === newIndex)
                return stack

            if (newIndex >= stack.length) {
                var key = newIndex - stack.length + 1
                while (key--) {
                    stack.push(undefined)
                }
            }

            stack.splice(newIndex, 0, stack.splice(oldIndex, 1)[0])
            return stack
        }
    }
};
</script>

<style lang="scss">

.o-card {
    display: block;
    padding: 1em;
    cursor: pointer;
    overflow: hidden;

    &:before {
        @include pseudo-el($bg: $color-light);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transform: scale(0, 1);
        transform-origin: 100% 50%;
        transition: transform .4s $in-expo .1s, opacity .4s ease-in;
    }

    &:hover {

        &:before {
            opacity: 1;
            transform: scale(1);
            transform-origin: 0 50%;
            transition: transform .4s $out-expo 0s, opacity .4s ease-out;
        }
    }
}

.o-card__region {
    display: block;
    padding-top: .75em;
    padding-bottom: .75em;
    border-top: $border;
}

.o-card__title {
    line-height: 1.1;
}

.o-card__asset {
    display: block;
    width: 96%;
    height: 0;
    margin: 2em auto 1.5em;
    padding-top: 50%;
    background-position: 50% 50%;
    background-size: cover;
    transform: rotate(4deg);
}

.o-card__item {
    display: block;

    &--desc {
        margin-bottom: 1rem;
    }

    &--contributions {
        margin-top: 2rem;
    }

    &--finAdd {
        border-top: $border;
        margin-top: 2rem;
        padding-top: 1rem;
    }

    &--days {
        display: flex;
        align-items: center;

        .o-icon {
            margin-right: .5em;
        }
    }
}

.findAdd {

    &__headline {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        span {
            display: inline-block;
            padding-left: 0.5em;
        }
    }

    &__programs {
        margin-top: 0.25rem;

        li {
            display: inline-flex;
            align-items: center;
            margin-top: 0.5rem;
            margin-right: 0.75rem;

            line-height: 1;
            list-style-type: none;

            .icon {
                display: inline-block;
                width: 1.25em;
                margin-right: 0.35em;
                padding: 0.15em;
                background: #FFF;
                overflow: hidden;
                border-radius: 1em;
            }
        }
    }
}
.o-card__progress {
    display: block;
    width: 100%;
    height: 1em;
    margin-top: .5em;
    margin-bottom: .5em;
    background-color: $color-light;

    span {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $color-yellow;
        transform: scaleX(0);
        transform-origin: 0 50%;
    }
}

.o-card__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1em;
    padding-top: .5em;
    padding-bottom: .5em;
    border-top: $border;
    border-bottom: $border;
}

</style>
