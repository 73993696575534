// Intersection Observer polyfill
import 'intersection-observer';

import Vue from 'vue'
import VueMeta from 'vue-meta'
import VuePlyr from 'vue-plyr'
import VueAnalytics from 'vue-analytics';
import App from 'src/App.vue'
import { router } from 'src/router'
import 'src/filters'
import store from 'src/store';
import { i18n, defaultLocal } from 'src/i18n'
import view from 'src/directives/view'
import gsap from 'src/vendors/gsap';


// Global config
Vue.config.productionTip = false

// Meta descriptions
Vue.use(VueMeta, {})

// Analytics
const isProd = process.env.NODE_ENV === 'production';
Vue.use(VueAnalytics, {
    id: 'UA-59718633-24',
    router,
    debug: {
        enabled: !isProd,
        sendHitTask: isProd,
    },
});

// Video player
Vue.use(VuePlyr, {
    plyr: {
        fullscreen: { enabled: false }
    }
})

// Vue instance
new Vue({
    router,
    i18n,
    store,
    render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
