export default {
    sitename: 'Mille et UN fund',
    meta: {
        title: ' Mille et UN fund for young people',
        description: 'The Mille et UN Fund for Young People is the first major government project that brings together crowdfunding, contributions from businesses, and support from the public sector',
    },
    header: {
        lang: 'Français',
    },
    stepform: {
        yes: 'Yes',
        no: 'No',
        or: 'OR',
        info: 'You have answered “No” to at least one of the eligibility requirements.',
        btn: {
            submit: 'Submit your project to the Mille et UN Fund on La&nbsp;Ruche',
            submitAnyway: 'Submit your project anyway to the Mille et UN Fund for Young People on La&nbsp;Ruche',
            submitRuche: 'Submit your project only on La&nbsp;Ruche',
        }
    },
    card: {
        collected: 'collected out of',
        daysleft: 'days remaining',
        progress: 'Financed',
        finished: 'Finished',
        finAdd: {
            label: 'Additional financing',
            total: 'Total:',
            M1: 'M1:',
            partM1: 'M1 participation:'
        }
    },
    btn: {
        start: 'Start project',
        close: 'Close',
        return: 'Back to website',
        eligibility: 'Eligibility requirements',
        playVideo: 'Watch video',
        info: 'Informations',
    },
    footer: {
        youth: 'for the youth',
        //sajqc: 'An initiative of',
        //laruche: 'Powered by',
        partnersTitle: 'A joint initiative by',
        privacy: 'Privacy',
        conditions: 'Terms of use',
        copyright: 'Mille et UN fund for the youth. <br>All rights reserved.',
    },
    aria: {
        accordionBtn: 'Show/Hide content',
        sliderPrev: 'Previous',
        sliderNext: 'Next',
    }
}
