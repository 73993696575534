<template>
    <div
        v-if="links.length > 0"
        class="c-block-links"
    >
        <btn
            v-for="(item, i) in links"
            v-if="item.link.url || item.link.id"
            :key="`block-link-${i}`"
            iconAfter="arrow-right"
            class="c-block-links__link | --underline"
            :tag="item.link.url ? 'a' : 'router-link'"
            :href="item.link.url ? item.link.url : getUrl(item.link.id)"
            :target="item.link.target"
            :label="item.label ? item.label : item.link.url"
        />
    </div>
</template>

<script>
import Btn from 'components/Btn';

import { allRoutes } from 'src/router';

export default {
    name: 'BlockLinks',
    components: {
        Btn,
    },
    props: {
        links: {
            type: Array,
        },
    },
    methods: {
        getUrl(id) {
            const route = allRoutes.find((route) => route.meta.prismicID === id)
            return typeof route === 'undefined' ? '/' : route.path
        }
    }
};
</script>

<style lang="scss" scoped>

.c-block-links {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 5vh;

    @media #{md("xs", "max")} {
        flex-direction: column;
    }
}

.c-block-links__link {
    width: calc(50% - .5em);

    .js-reveal & {

    }

    .js-reveal.is-visible & {

    }

    &:before {
        @include pseudo-el($height: 1px, $bg: currentColor);
        position: absolute;
        bottom: 0;
        left: 0;
        transition: width .3s ease-out;
    }

    &:hover {

        &:before {
            width: calc(100% - 2.5em);
        }
    }

    @media #{md("xs", "max")} {
        width: 100%;

        &:nth-child(n+2) {
            margin-top: 1em;
        }
    }

    @media #{md("xs")} {
        min-width: 16em;
    }
}

</style>
