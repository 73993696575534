<template>
    <button
        @click="click"
        @mouseenter="mouseEnter = true"
        :class="[
            { 'o-btn-start--invert' : invert },
            { 'is-hovering' : mouseEnter }
        ]"
        class="o-btn-start"
    >
        <shape
            shape="circle"
            color="dark"
        >
            <span v-if="closeModal && currentModal">
                {{ $t('btn.close') }}
                <icon icon="cross" />
            </span>
            <span v-else>
                {{ $t('btn.start') }}
                <icon :icon="invert ? 'arrow-up' : 'arrow-down'" />
            </span>
        </shape>
    </button>
</template>

<script>
import Icon from 'objects/Icon'
import Shape from 'objects/Shape'

import { defaultLocal } from 'src/i18n'

export default {
    name: 'BtnStart',
    components: {
        Icon,
        Shape,
    },
    data: () => ({
        mouseEnter: false
    }),
    props: {
        invert: {
            type: Boolean,
            default: false
        },
        closeModal: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        click() {
            if (this.closeModal && this.currentModal) {
                this.$store.dispatch('updateModal', null)
            } else if(this.$router.currentRoute.meta.id === 'home') {
                this.scroll()
            } else {
                this.$router.push({ path: this.$i18n.locale === defaultLocal ? '/' : `/${this.$i18n.locale}`, hash: 'steps' })
            }
        },
        scroll() {
            const $target = document.getElementById('steps')
            TweenLite.to(window, 1, {
                scrollTo: {
                    y: $target.getBoundingClientRect().top + window.scrollY - 100,
                    autoKill: false,
                },
                ease: Expo.easeInOut,
            })
        },
    },
    computed: {
        currentModal() {
            this.mouseEnter = true
            return this.$store.state.video !== null
        }
    },
    watch: {
        mouseEnter(enter) {
            if(!enter) return

            setTimeout(() => {
                this.mouseEnter = false
            }, 800);
        }
    }
};
</script>


<style lang="scss">

.o-btn-start {
    display: block;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    .o-shape {
        --width: var(--btn-width, 10em);
        align-items: flex-end;
        padding-top: 1em;
        padding-bottom: 1em;
        transition: all .4s ease-out;
        transform-origin: 50% 0;

        &:before {
            transition: all .4s ease-out;
            will-change: border-radius, transform;
        }

        &:after {
            @include pseudo-el($bg: $color-grey);
            position: absolute;
            top: 0;
            left: 0;
            border-radius: inherit;
            opacity: 0;
            transition: opacity .4s ease-out;
        }
    }

    .o-shape__inner span {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 700;
    }

    .o-icon {
        margin-top: 1em;
    }

    &.is-hovering {

        .o-shape:before {
            animation: blob .8s ease-out;
        }
    }

    &:active {

        .o-shape:before {
            transform: scale(1.1);
            transition: transform .1s;
        }

    }

    &--invert {

        .o-shape {
            align-items: center;
        }

        .o-shape__inner > span {
            flex-direction: column-reverse;
        }

        .o-icon {
            margin-top: -2em;
            margin-bottom: 1em;
        }

        &:hover {

            .o-shape {
                //transform: translate(0, -5%);
            }
        }
    }
}
</style>
