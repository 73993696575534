<template>
    <span class="o-grid">
        <span
            v-for="col in columns + 1"
            :key="`column-${col}`"
            class="o-grid__el o-grid__el--col"
            :style="{ left: `calc(${(col - 1)/columns} * 100%)` }"
        ></span>
        <span
            v-for="row in rows + 1"
            :key="`row-${row}`"
            class="o-grid__el o-grid__el--row"
            :style="{ top: `calc(${(row - 1)/rows} * 100%)` }"
        ></span>
    </span>
</template>

<script>

export default {
    name: 'Grid',
    props: {
        columns: {
            type: Number,
            default: 6,
        },
        rows: {
            type: Number,
            default: 6,
        },
    },
};
</script>

<style lang="scss">

.o-grid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:before {
        content: var(--rows)
    }
}

.o-grid__el {
    position: absolute;
    display: block;
    background-color: currentColor;

    &--col {
        top: 0;
        width: 1px;
        height: 100%;
    }

    &--row {
        left: 0;
        width: 100%;
        height: 1px;
    }
}

</style>
