<template>
    <footer class="l-footer">

        <div class="grid">
            <div class="grid__row">
                <div class="w-12/12 w-8/12@sm --offset-4@sm w-7/12@md --offset-5@md">

                    <router-link
                        :to="'/'|url"
                        class="l-footer__logo"
                    >
                        <icon :icon="`logo-${$i18n.locale}`" class="--underline" />
                        <span>{{ $t('footer.youth') }}</span>
                    </router-link>

                    <div class="l-footer__partners">
                        <h4 class="l-footer__partners-title | --underline">{{ $t('footer.partnersTitle') }}</h4>

                        <ul>
                            <li v-if="data.sajqc_link.url && data.sajqc_logo.url">
                                <a
                                    :href="data.sajqc_link.url"
                                    :title="data.sajqc_logo.alt"
                                    :target="data.sajqc_link.target"
                                    rel="noopener"
                                >
                                    <img
                                        :src="data.sajqc_logo.url"
                                        :alt="data.sajqc_logo.alt"
                                    >
                                </a>
                            </li>
                            <li v-if="data && data.laruche_link.url && data.laruche_logo.url">
                                <a
                                    :href="data.laruche_link.url"
                                    :title="data.laruche_logo.alt"
                                    :target="data.laruche_link.target"
                                    rel="noopener"
                                >
                                    <img
                                        :src="data.laruche_logo.url"
                                        :alt="data.laruche_logo.alt"
                                    >
                                </a>
                            </li>
                        </ul>
                    </div>

                    <ul class="l-footer__socials">
                        <li
                            v-if="data"
                            v-for="social in data.socials"
                            :key="social.label"
                        >
                            <a
                                :href="social.link.url"
                                :title="social.name"
                                :target="social.link.target"
                                rel="noopener"
                            >
                                <icon :icon="social.label" />
                                <span>{{ social.name }}</span>
                            </a>
                        </li>
                    </ul>

                    <btn
                        tag="a"
                        type="stroke"
                        :href="`mailto:${data.email}`"
                        target="_blank"
                        class="l-footer__email"
                    >
                        {{ data.email }}
                    </btn>


                    <ul class="l-footer__links">
                        <li
                        >
                            <router-link
                                :to="'/vie-privee'|url"
                                class="o-hover-text"
                                v-html="$t('footer.privacy')"
                            />
                        </li>
                        <li
                        >
                            <router-link
                                :to="'/conditions-utilisation'|url"
                                class="o-hover-text"
                                v-html="$t('footer.conditions')"
                            />
                        </li>
                    </ul>

                    <div class="l-footer__bottom">
                        <span class="l-footer__copy">&copy; {{ year }}, <span v-html="$t('footer.copyright')"></span></span>
                        <a href="https://mambomambo.ca" title="MamboMambo, studio de design graphique et web" class="l-footer__mambo | o-hover-text">MamboMambo</a>
                    </div>

                </div>
            </div>
        </div>

        <div class="l-footer__deco">
            <shape
                shape="square"
                color="blue"
                v-view="parallax"
            />
            <btn-start
                :invert="true"
                class="l-footer__btn"
                v-view="parallax"
            />
            <shape
                shape="line"
                color="red"
                v-view="parallax"
            />
        </div>

    </footer>
</template>

<script>
import Icon from 'objects/Icon'
import Shape from 'objects/Shape'
import Btn from 'components/Btn'
import BtnStart from 'objects/BtnStart'

import Variables from 'src/mixins/variables';

export default {
    name: 'AppFooter',
    components: {
        Btn,
        Icon,
        Shape,
        BtnStart,
    },
    mixins: [ Variables ],
    computed: {
        year: () => new Date().getFullYear(),
        data() {
            return this.$store.state.global
        }
    },
    methods: {
        parallax(e) {
            let x, y;
            if(e.target.classList.contains('o-shape--square')) {
                x = e.percent.top * this.W.w/40
                y = -e.percent.top * this.W.h/40
            } else if(e.target.classList.contains('o-shape--line')) {
                x = -e.percent.top * this.W.w/20
                y = e.percent.top * this.W.h/20
            } else {
                x = -e.percent.top * this.W.w/30
                y = 0
            }

            TweenLite.to(e.target, .2, { x, y })
        }
    }
};

</script>

<style lang="scss">

.l-footer {
    margin-top: 10vh;
}

.l-footer__logo {
    display: inline-block;

    span {
        display: block;
        margin-top: -.4em;
    }
}

.l-footer__partners {
    margin-top: 2em;

    ul {
        @include reset-list;
        display: flex;
    }

    li {
        flex: 1 1 0;
    }

    span {
    }

    img {
        @include img;
        max-width: 12em
    }

    a {
        display: block;
        cursor: pointer;
        transition: transform .4s ease-out;

        &:hover {
            transform: scale(1.02);
        }
    }
}

.l-footer__partners-title {
    display: block;
    max-width: calc(50% + 12em);
    margin-bottom: 1.5em;
    font-weight: 700;
}

.l-footer__socials {
    @include reset-list;
    display: flex;
    width: 100%;
    margin-top: 3em;
    margin-bottom: 2em;
    border-top: $border;
    border-bottom: $border;

    li {
        flex: 1 1 0;
        padding-top: .75em;
        padding-bottom: .75em;
        text-align: center;

        &:nth-child(n+2) {
            border-left: $border;
        }
    }

    a {
        position: static;
        display: inline-flex;
        align-items: center;
        transition: color .4s ease-out;

        &:before {
            @include pseudo-el($width: auto, $height: auto, $bg: $color-dark);
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: -1px;
            left: -1px;
            transform: scale(1, 0);
            transform-origin: 50% 0;
            transition: transform .4s ease-out;
        }

        &:hover {
            color: $color-light;

            &:before {
                transform: scale(1);
                transform-origin: 50% 100%;
            }
        }
    }

    span {
        display: inline-block;
        margin-left: .75em;
        font-weight: 700;
    }

    @media #{md("sm")} {
        margin-bottom: 4em;
    }
}

.l-footer__bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 7vh;
    font-size: .875em;

    @media #{md("xs", "max")} {

        > * {
            margin-bottom: .5rem;
        }
    }

    @media #{md("sm")} {
        margin-bottom: 1rem;
    }
}

.l-footer__copy {
    display: inline-block;
    margin-right: 2em;
}

.l-footer__links {
    @include reset-list;
    margin: 2em -1em 1em;
    display: block;

    li {
        display: inline-block;
        margin-right: 1em;
        margin-left: 1em;
    }
}

.l-footer__btn {
    z-index: 1;

    @media #{md("sm", "max")} {
        --btn-width: 50vw;

        margin-bottom: -20%;
        left: 25%;
    }

    @media #{md("sm")} {
        --btn-width: 25vmax;

        position: absolute;
        bottom: calc(-1 * var(--btn-width)/4);
        left: calc(50% - var(--btn-width)/2);
    }
}

.l-footer__deco {

    .o-shape {

        &--square {
            --width: 14vmax;
            transform: rotate(15deg);
        }

        &--line {
            --width: 14vmax;
            transform: rotate(-70deg);
            transform-origin: 0 50%;
        }
    }

    @media #{md("sm", "max")} {
        //overflow: hidden;

        .o-shape {

            &--square {
                --width: 30vw;
                bottom: .5vw;
                left: calc(50% - var(--width)/2);
                margin-top: 3vw;
                transform: rotate(10deg);
            }

            &--line {
                --width: 35vw;
                position: absolute;
                top: 0;
                right: 77.75%;
                transform: rotate(-50deg);
                transform-origin: 100% 0;
            }
        }
    }

    @media #{md("sm")} {
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(4/12 * 100%);

        .o-shape {

            &--square {
                position: absolute;
                bottom: calc(25vmax * 3/4);
                left: calc(50% - var(--width)/2 + 2.5vmax);
                transform: rotate(15deg);
            }

            &--line {
                position: absolute;
                left: calc(-1 * var(--width)/6);
                bottom: calc(-1 * var(--width)/6);
            }
        }
    }

    @media #{md("xxl")} {
        width: calc(4.5/12 * 100%);
    }
}

</style>
