<template>
    <div
        class="c-accordion"
        :class="{
            'is-open': isOpen,
            'c-accordion--upside-down': upsideDown
        }"
    >
        <header
            class="c-accordion__header"
            @click.prevent="isOpen ? close() : open()"
        >
            <slot name="title">
                <h2
                    v-if="title"
                    class="c-accordion__title || t-s"
                >
                    {{ title }}
                </h2>
            </slot>
            <button
                class="c-accordion__btn"
                :aria-label="$t('aria.accordionBtn')"
            >
                <span
                    v-if="triggerLabel"
                    class="c-accordion__btn-label"
                >
                    {{ triggerLabel }}
                </span>
                <round
                    icon="plus"
                    size="md"
                />
            </button>
        </header>
        <div
            ref="container"
            class="c-accordion__container"
            :class="{ 'has-scroll' : hasScroll }"
        >
            <div ref="inner" class="c-accordion__content">
                <slot>
                    <div
                        class="t-cms"
                        v-html="content"
                    ></div>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import Round from 'objects/Round';

import debounce from 'lodash/debounce';
import gsap from 'gsap';

export default {
    name: 'Accordion',
    components: {
        Round,
    },
    data: () => ({
        isOpen: false,
        isActive: true,
        hasScroll: false,
        opts: {
            scroll: false,
            watchCSS: false,
            maxHeight: false,
            open: false
        }
    }),
    props: {
        title: {
            type: String,
            default: null,
        },
        triggerLabel: {
            type: String,
            default: null,
        },
        content: {
            type: String,
            default: null,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        upsideDown: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.opts = Object.assign(this.opts, this.options)
        this.isOpen = this.opts.open
    },
    mounted() {
        // Window event
        window.addEventListener('resize', debounce(this.watchCSS, 100));
    },
    methods: {
        open() {
            if (!this.isActive) {
                return;
            }

            this.isOpen = true;

            const height = this.$refs.inner.offsetHeight;

            TweenLite.to(this.$refs.container, 1, {
                height: this.opts.maxHeight && height > this.opts.maxHeight ? this.opts.maxHeight : height,
                opacity: 1,
                onStart: () => {
                    if (height === this.opts.maxHeight) {
                        this.hasScroll = true;
                    }
                },
                onComplete: () => {
                    TweenLite.set(this.$refs.container, {
                        height: 'auto'
                    });
                },
                ease: Expo.easeInOut,
            });

            if (this.opts.scroll) {
                const scrollY = this.$refs.container.getBoundingClientRect().top + window.scrollY;
                this.animScroll(scrollY);
            }
        },
        close() {
            if (!this.isActive) {
                return;
            }

            this.isOpen = false;

            TweenLite.to(this.$refs.container, 1, {
                height: 0,
                opacity: 0,
                onComplete: () => {
                    TweenLite.set(this.$refs.container, {
                        height: 0
                    });
                    this.hasScroll = false;
                },
                ease: Expo.easeInOut,
            });
        },
        toggle() {
            if (this.isOpen) {
                this.close();
            } else {
                this.open();
            }
        },
        animScroll(y) {
            TweenLite.to(window, 1, {
                scrollTo: {
                    y: y,
                    autoKill: false,
                },
                ease: Expo.easeInOut,
            });
        },

        watchCSS() {
            if (!this.opts.watchCSS) return;

            const afterContent = getComputedStyle(this.$el, ':after').content;

            if (afterContent.indexOf('accordion') !== -1) {
                this.isActive = true;
            } else {
                this.isActive = false;
                this.$refs.container.removeAttribute('style');
            }
        },
    },
    watch: {
        isOpen(open) {
            if(open) {
                this.open()
            } else {
                this.close()
            }
        }
    },
    beforeDestroy() {
        // Remove Window event
        window.removeEventListener('resize', debounce(this.watchCSS, 100));
    },
};
</script>

<style lang="scss">

.c-accordion {
    border-top: $border;

    // Code to add to enable accordion if watchCSS is enabled
    // &:after {
    //     content: "accordion";
    //     display: none;
    // }

    &.is-open {

        .c-accordion__content {
            pointer-events: initial;
        }

        .c-accordion__btn .o-icon {
            transform: rotate(45deg);
        }
    }

    &--upside-down {
        display: flex;
        flex-direction: column;

        .c-accordion__header {
            order: 2
        }

        .c-accordion__container {
            order: 1
        }

        .c-accordion__btn {
            transform: rotate(180deg);
        }

        &.is-open {

            .c-accordion__btn {
                transform: rotate(360deg);
            }
        }
    }

}


.c-accordion__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //padding-top: 1.5em;
    //padding-bottom: 1.5em;
    padding-left: 4.5rem;
    user-select: none;
    cursor: pointer;

    &:after {
        @include pseudo-el($height: 1px, $bg: currentColor);
        position: absolute;
        top: 100%;
        left: 0;
    }

    &:hover,
    .is-open & {

        .c-accordion__number {
            color: $color-light;

            &:before {
                transform: scale(1);
            }
        }
    }

    &:hover {

        .o-round:before {
            --color-bg: transparent;
            border-width: .2em;
            border-radius: 0;
        }
    }

    &:hover,
    .is-open &:hover {

        .o-round:before {
            transform: rotate(135deg) scale(.95);
        }
    }

    .is-open & {

        .o-round {
            --color-bg: transparent;
            --color-border: #{$color-dark};

            &:before {
                border-width: .2em;
                transform: rotate(180deg);
            }
        }

    }

    @media #{md("md")} {
        padding-left: 7rem;
    }
}

.c-accordion__number {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 100%;
    transition: color .4s ease-out;

    &:before {
        @include pseudo-el($height: auto, $bg: $color-dark);
        z-index: -1;
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: 0;
        transform: scale(0, 1);
        transform-origin: 100% 50%;
        transition: transform .4s ease-out;
    }

    &:after {
        @include pseudo-el($width: 1px, $bg: currentColor);
        position: absolute;
        top: 0;
        right: 0;
    }
}


.c-accordion__title {
    width: 100%;
    padding: 1em 1.5em;

    @media #{md("sm")} {
        padding-right: 2.5em;
        padding-left: 2.5em;
    }
}


.c-accordion__btn {
    display: flex;
    align-items: center;
    transition: transform .2s $in-out-circ;

    .o-icon {
        transition: transform .3s $in-out-circ;
    }

    .o-round {
        --color-bg: #{$color-blue};
        --color-border: #{$color-blue};
    }
}

.c-accordion__btn-label {
    display: block;
    margin-right: .75em;
    font-weight: 700;
}


.c-accordion__container {
    height: 0;
    opacity: 0;
    overflow: hidden;

    &.has-scroll {

        &:after {
            @include pseudo-el($height: 4em);
            position: absolute;
            bottom: 0;
            left: 0;
            @include linear-gradient(to top, $color-light, $color-light 1.5em, rgba(255, 255, 255, 0) 100%);
            pointer-events: none;
        }

        .c-accordion__content {
            padding-bottom: 3em;
        }
    }
}


.c-accordion__content {
    // max-height: 400px;
    padding: 1.5em 2em;
    pointer-events: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

</style>
