export default {
    sitename: 'Fonds Mille et UN',
    meta: {
        title: 'Fonds Mille et UN pour la jeunesse',
        description: 'Le Fonds Mille et UN pour la jeunesse est le premier projet gouvernemental d’envergure qui allie le financement participatif, la contribution d’entreprises et le soutien du gouvernement',
    },
    header: {
        lang: 'English',
    },
    stepform: {
        yes: 'Oui',
        no: 'Non',
        or: 'OU',
        info: 'Vous avez répondu "non" à au moins une des conditions mentionnées plus haut',
        btn: {
            submit: 'Soumettre mon projet au Fonds&nbsp;Mille&nbsp;et&nbsp;UN sur&nbsp;La&nbsp;Ruche',
            submitAnyway: 'Soumettre tout de même mon projet au Fonds&nbsp;Mille&nbsp;et&nbsp;UN sur&nbsp;La&nbsp;Ruche',
            submitRuche: 'Soumettre mon projet sur&nbsp;La&nbsp;Ruche',
        }
    },
    card: {
        collected: 'récoltés sur',
        daysleft: 'jours restants',
        progress: 'Financé à',
        finished: 'Terminé',
        finAdd: {
            label: 'Financement additionnel',
            total: 'Total :',
            M1: 'M1 :',
            partM1: 'Participation du M1 :'
        }
    },
    btn: {
        start: 'Lancer un projet',
        close: 'Fermer',
        return: 'Retour au site',
        eligibility: 'Critères d’admissibilité',
        playVideo: 'Visionner la vidéo',
        info: 'Informations',
    },
    footer: {
        youth: 'pour la jeunesse',
        // sajqc: 'Une initiative du',
        // laruche: 'Activé par',
        partnersTitle: 'Une initiative conjointe',
        privacy: 'Vie privée',
        conditions: 'Conditions d\'utilisation',
        copyright: 'Fonds Mille et UN pour la jeunesse. <br>Tous droits réservés.',
    },
    aria: {
        accordionBtn: 'Afficher/Cacher le contenu',
        sliderPrev: 'Précédent',
        sliderNext: 'Suivant',
    }
}
