<template>
    <component v-if="!$store.state.loading" :is="component" />
</template>

<script>
import Home from 'views/Home';
import AppContainer from 'layout/AppContainer';
import Content from 'views/Content';

import { allRoutes } from 'src/router';

export default {
    name: 'AppContainer',
    data: () => ({
        component: null
    }),
    created() {
        this.setComponent()
    },
    watch: {
        $route() {
            this.setComponent()
        }
    },
    methods: {
        setComponent() {
            const route = allRoutes.find((route) => route.name == this.$route.name)
            this.component = route.component === AppContainer ? Home : route.component
        }
    }
}
</script>
