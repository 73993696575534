<template>
    <span
        class="o-anim-text"
        :class="{ 'is-visible' : reveal }"
    >
        <span
            v-for="(word, i) in words"
            :key="`word-${i}`"
            :class="[ word === '<br>' ? 'o-anim-text__b' : 'o-anim-text__w' ]"
        >
            <span
                v-if="word !== '<br>'"
                class="o-anim-text__t"
                v-html="word"
            ></span>
        </span>
    </span>
</template>

<script>
export default {
    name: 'Icon',
    props: {
        text: {
            type: String,
            required: true,
        },
        delay: {
            type: Number,
            default: null
        },
    },
    data: () => ({
        reveal: false
    }),
    mounted() {
        if(this.delay !== null) {
            this.$el.style.setProperty('--delay', this.delay)
        }
    },
    computed: {
        words() {
            let words = this.text.replace('<br>', ' <br> ')
            words = words.split(' ')
            return words
        }
    },
    methods: {
        show() {
            this.reveal = true
        },
        hide() {
            this.reveal = false
        }
    }
};
</script>

<style lang="scss">

.o-anim-text {
    --delay: 0;

    display: flex;
    flex-wrap: wrap;
    margin-right: -.15em;
    margin-left: -.15em;

    &.is-visible,
    .js-reveal.is-visible & {
        @include anim-text-in;
    }
}

.o-anim-text__w {
    height: 1.2em;
    margin: calc(-.2em/2) .15em;
    overflow: hidden;
}

.o-anim-text__b {
    display: block;
    width: 100%;
    height: 1em;
}

.o-anim-text__t {
    display: block;
    transition: inherit;

    .js-reveal & {
        opacity: 0;
        transform: translate(0, 110%);
    }
}

</style>
