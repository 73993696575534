import Vue from 'vue'
import Vuex from 'vuex'

import { i18n } from 'src/i18n'

// Prismic
import Prismic from 'prismic-javascript';

const apiEndpoint = process.env.VUE_APP_PRISMIC_API
const accessToken = process.env.VUE_APP_PRISMIC_TOKEN

const partnerID = process.env.VUE_APP_PARTNER_ID
// OLD
//const projectsUrl = `https://laruchequebec.com/explorer/projets/?ruchePartner=34&json`
// NEW
const projectsUrl = `https://production.api.laruchequebec.com/projects/thumbnails/explore?from=0&size=100&program=${partnerID}&statuses=PUBLISHED,COMPLETED_SUCCESS&expand`

// Vuex
Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        global: null,
        projects: [],
        page: null,
        pages: [],
        loading: 0,
        video: null,
        showBanner: true,
    },

    actions: {

        firstLoad({commit, state}) {

            // Start load
            commit('startLoad')

            /*
            // Load all data
            Prismic
                .getApi(apiEndpoint, { accessToken })
                .then((api) => api.query(''))
                .then((response) => {
                    const results = response.results
                    console.log(results)
                })
            */

            // Load global data
            Prismic
                .getApi(apiEndpoint, { accessToken })
                .then((api) => api.query(Prismic.Predicates.at('document.type', 'settings')))
                .then((response) => {
                    const results = response.results
                    commit('setGlobal', response.results[0].data)

                    // Stop load
                    commit('stopLoad')
                })

            // Load projects
            fetch(projectsUrl)
                .then((resp) => resp.json())
                .then((data) => {

                    // Shuffle array
                    let items = data.items.sort(() => Math.random() - 0.5);

                    // Get non-completed project
                    let published = items.filter(project => project.status === 'PUBLISHED')

                    // Get success/completed project
                    let success = items.filter(project => project.status === 'COMPLETED_SUCCESS')

                    // Keep 3 pubished projects
                    if (published.length > 3)
                        published = published.slice(0, 3)

                    // Get real difference (in case there's less than 3 published project
                    const diff = 6 - published.length

                    // Complete the list from the 'success' projects
                    success =  success.slice(0, diff)

                    // Merge
                    items = [...published, ...success]

                    // Suffle again
                    items = items.sort(() => Math.random() - 0.5);

                    commit('setProjects', items)
                })
                .catch((e) => console.log(`Fetch error: ${e}`))
        },

        loadPage({commit, state}, {id, prismicID}) {

            // Start load
            commit('startLoad')

            // If page is already loaded, update data
            if(typeof state.pages[i18n.locale] !== 'undefined' && typeof state.pages[i18n.locale][id] !== 'undefined') {
                commit('setPage', state.pages[i18n.locale][id])

                // Stop load
                commit('stopLoad')
            } else {

                Prismic
                    .getApi(apiEndpoint, { accessToken })
                    .then((api) => api.query(Prismic.Predicates.at('document.id', prismicID), { lang: `${i18n.locale}-ca` }))
                    .then((response) => {
                        const results = response.results


                        if(results.length > 0) {

                            // Add data to pages
                            commit('addToPages', {
                                id: id,
                                page: results[0]
                            })

                            // Set page
                            commit('setPage', results[0].data)

                        }

                        // Stop load
                        commit('stopLoad')
                    })

            }

        },

        updateModal({commit}, data) {
            commit('setVideo', data)
        },

        hideBanner({commit}) {
            commit('setBanner', false)
        },
    },

    mutations: {
        setGlobal(state, data) {
            state.global = data
        },
        setProjects(state, data) {
            state.projects = data
        },
        addToPages(state, { id, page }) {

            // Add locale if not in array
            if(typeof state.pages[i18n.locale] === 'undefined') {
                state.pages[i18n.locale] = []
            }
            state.pages[i18n.locale][id] = page.data
        },
        setPage(state, data) {
            state.page = data
        },
        startLoad(state) {
            state.loading += 1
        },
        stopLoad(state) {
            state.loading -= 1
        },
        setVideo(state, data) {
            state.video = data
        },
        setBanner(state, isVisible) {
            state.showBanner = isVisible
        },
    },

})
