import Vue from 'vue'
import VueI18n from 'vue-i18n'

import fr from './translations/fr'
import en from './translations/en'

// Define locales
export const locales = ['fr', 'en']

// Define translations
const translations = {
    fr,
    en,
}

// Set default local
export const defaultLocal = 'fr'

// Use plugin
Vue.use(VueI18n)

// Instanciate plugin
export const i18n = new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || defaultLocal,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || defaultLocal,
    messages: translations
})
