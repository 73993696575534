<script>

import { RichText } from 'prismic-dom'
import linkResolver from 'src/link-resolver'

export default {
    name: 'PrismicRichText',
    functional: true,
    props: {
        field: {
            type: Array,
            required: true,
        },
        htmlSerializer: {
            type: Function,
            required: false,
        },
        wrapper: {
            type: String,
            required: false,
            default: 'div',
        }
    },
    render(h, {
        props, data, children, parent
    }) {

        const { field, htmlSerializer, wrapper } = props
        if(field.length === 0) return;

        const innerHTML = RichText.asHtml(
            field,
            linkResolver,
            htmlSerializer
        )

        return h(
            wrapper, {
                ...data,
                class: {
                    't-cms': true
                },
                domProps: {
                    innerHTML
                },
            },
        )
    }
}
</script>
