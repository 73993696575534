import Vue from 'vue';
import VueRouter from 'vue-router'
import store from './store'
import { locales, defaultLocal, i18n } from 'src/i18n'

import Home from 'views/Home'
import AppContainer from 'layout/AppContainer'
import Content from 'views/Content'

// Use plugin
Vue.use(VueRouter)

const routes = [
    {
        name: 'accueil',
        path: '',
        component: Home,
        meta: {
            id: 'home',
            prismicID: 'XbsZphEAACQAvLHW'
        }
    },
    {
        name: 'admissibilite',
        path: '/criteres-admissibilite',
        component: Content,
        meta: {
            id: 'eligibility',
            prismicID: 'XcGs7BEAACYA2cCk'
        }
    },
    {
        name: 'vie-privee',
        path: '/vie-privee',
        component: Content,
        meta: {
            id: 'privacy',
            prismicID: 'XcoRQREAACIACkAk'
        }
    },
    {
        name: 'contribuer',
        path: '/contribuer',
        component: Content,
        meta: {
            id: 'contribute',
            prismicID: 'XcoOEhEAACEACjIE'
        }
    },
    {
        name: 'conditions',
        path: '/conditions-utilisation',
        component: Content,
        meta: {
            id: 'conditions',
            prismicID: 'XcoRhxEAACMACkFk'
        }
    },
    {
        name: 'financement-participatif',
        path: '/financement-participatif',
        component: Content,
        meta: {
            id: 'financing',
            prismicID: 'XcoOwhEAACQACjUP'
        }
    },
    {
        name: 'covid19',
        path: '/covid19',
        component: Content,
        meta: {
            id: 'covid19',
            prismicID: 'Xo3IvhAAACEAJkXn'
        }
    },
    {
        name: 'home',
        path: '/:lang',
        component: AppContainer,
        meta: {
            id: 'home',
            prismicID: 'XcL0GhEAACMA6soZ'
        },
        children: [
            {
                name: 'eligibility',
                path: 'criteres-admissibilite',
                component: Content,
                meta: {
                    id: 'eligibility',
                    prismicID: 'XcHfGhEAACYA2p7X'
                }
            },
            {
                name: 'privacy',
                path: 'vie-privee',
                component: Content,
                meta: {
                    id: 'privacy',
                    prismicID: 'XcwMNBEAACMAExdR'
                }
            },
            {
                name: 'contribute',
                path: 'contribuer',
                component: Content,
                meta: {
                    id: 'contribute',
                    prismicID: 'XcwMKhEAACIAExce'
                }
            },
            {
                name: 'conditions',
                path: 'conditions-utilisation',
                component: Content,
                meta: {
                    id: 'conditions',
                    prismicID: 'XcwMHxEAACMAExbv'
                }
            },
            {
                name: 'financing',
                path: 'financement-participatif',
                component: Content,
                meta: {
                    id: 'financing',
                    prismicID: 'XcwMExEAACMAExax'
                }
            },
            {
                name: 'covid19',
                path: 'covid19',
                component: Content,
                meta: {
                    id: 'covid19',
                    prismicID: 'Xo3IzBAAAB8AJkYj'
                }
            },
        ]
    },
];

const allRoutes = []
routes.forEach((route) => {
    allRoutes.push(route)
    if(route.children) {
        route.children.forEach((child) => {
            allRoutes.push(child)
        })
    }
})

// Create the router with the routes
const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes,
    scrollBehavior: function(to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    },
    linkActiveClass: 'is-current',
})

router.beforeEach((to, from, next) => {
    let lang = to.params.lang;

    if (!lang) {
        lang = defaultLocal;
    }

    // 404
    if(!locales.find(local => local === lang)
        || to.matched.length === 0 ) {
        next({ name: 'accueil' });
    } else {

        if(i18n.locale !== lang) {
            i18n.locale = lang
        }

        store.dispatch('loadPage', {
            id: to.meta.id,
            prismicID: to.meta.prismicID
        });

        next()
    }
})

// Export router
export {routes, router, allRoutes};
