import { i18n, defaultLocal } from 'src/i18n'
import debounce from 'lodash/debounce';

export default {
    data() {
        return {
            W: {
                w: window.innerWidth,
                h: window.innerHeight,
            },
            urlPrefix: ''
        }
    },
    created() {

        const set = () => {
            // Define window vars
            this.W = {
                w: window.innerWidth,
                h: window.innerHeight,
            }
        }

        // Set size
        set()

        // Window events
        window.addEventListener('resize', debounce(set, 100));
    },
    watch: {
        $route() {
            this.urlPrefix =  i18n.locale === defaultLocal ? '' : `/${i18n.locale}`
        }
    }
}
