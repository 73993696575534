import Vue from 'vue'

import isEqual from 'lodash/isEqual';

const roundPercent = Symbol('roundPercent');
const whichTransitionEvent = Symbol('whichTransitionEvent');

const viewClass = new class View {

    constructor() {
        // console.log(`${this.constructor.name}:init`)

        this.class = {
            reveal: 'js-reveal',
            revealVisible: 'is-visible',
        }

        // Correct transitionend event name
        this.transitionEnd = this[whichTransitionEvent]() ? this[whichTransitionEvent]() : 'transitionsEnd';

        this.pos, this.lastPos = {};

        this.items = {}
        this.activeItems = []

        // Define observer
        let $target
        this.observer = new IntersectionObserver($observables => {
            this.lastPos = {}
            $observables.forEach(($observable) => {
                $target = $observable.target
                if ($observable.isIntersecting) {
                    this.activeItems.push($target.id)
                } else {
                    this.activeItems = this.activeItems.filter(i => i !== $target.id)
                }
            })
        });

        // Window events
        window.addEventListener('load', () => {

            // Scroll in page
            this.scroll = {
                x: window.pageYOffset,
                y: window.pageXOffset
            }

            // Set sizes
            this.setSizes()

            // RAF Loop
            window.requestAnimationFrame(this.loop.bind(this))
        })

        window.addEventListener('resize', this.setSizes.bind(this))
    }

    setSizes() {
        // console.log(`${this.constructor.name}:setSizes`)

        this.W = {
            w: window.innerWidth,
            h: window.innerHeight,
        }

        this.doc = {
            h: document.documentElement.scrollHeight,
            w: document.documentElement.scrollWidth
        }
    }

    observeItem(id, item) {
        // console.log(`${this.constructor.name}:observeItem`)

        this.items[id] = item
        this.observer.observe(this.items[id].element)
    }

    unobserveItem(id) {
        // console.log(`${this.constructor.name}:unobserveItem`, id)

        this.observer.unobserve(this.items[id].element)
        delete this.items[id]
        this.activeItems = this.activeItems.filter(i => i !== id)
    }

    loop() {
        // console.log(`${this.constructor.name}:loop`)

        this.pos = {
            top: window.pageYOffset,
            right: window.pageXOffset + this.W.w,
            bottom: window.pageYOffset + this.W.h,
            left: window.pageXOffset,
        }

        if(isEqual(this.pos, this.lastPos)) {
            requestAnimationFrame(this.loop.bind(this));
            return;
        } else {
            this.lastPos = this.pos;
        }

        let i, inType, eventType;
        this.activeItems.forEach(id => {
            i = this.items[id]

            inType = this.getInType(i)

            if (typeof i.handler === 'function') {
                i.handler({
                    // type: eventType,
                    percent: {
                        inView: inType.percentInView,
                        top: inType.percentTop,
                        left: inType.percentLeft,
                        center: {
                            x: inType.percentCenterX,
                            y: inType.percentCenterY,
                        },
                        scroll: {
                            x: this[roundPercent](this.pos.top / (this.doc.h - this.W.h)),
                            y: this[roundPercent](this.pos.left / (this.doc.w - this.W.w))
                        }
                    },
                    scroll: {
                        x: this.pos.top - this.scroll.x,
                        y: this.pos.left - this.scroll.y,
                    },
                    target: i.element
                })
            }

            if (i.reveal === true) {

                this.items[i.id].reveal = false
                i.element.classList.add(this.class.revealVisible)

                if(i.delay) {
                    let item = i
                    setTimeout(() => {
                        this.revealEnd(item)
                    }, item.delay * 1000);
                    // this.revealTimeout(i)
                } else {
                    i.element.addEventListener(this.transitionEnd, this.revealEnd(i));
                }
            }
        })

        this.scroll = {
            x: this.pos.top,
            y: this.pos.left,
        };


        requestAnimationFrame(this.loop.bind(this))
    }

    revealEnd(i) {
        // console.log(`${this.constructor.name}:revealEnd`, i)

        i.element.classList.remove(this.class.reveal)
        i.element.classList.remove(this.class.revealVisible)
        i.element.removeEventListener(this.transitionEnd, this.revealEnd)
    }

    getInType(i) {
        // console.log(`${this.constructor.name}:getInType`, i)

        let rect = i.element.getBoundingClientRect();
        let elementTop = rect.top + this.pos.top;
        let elementLeft = rect.left + this.pos.left;
        let elementBottom = elementTop + rect.height;
        let topIn = elementTop > this.pos.top && elementTop < this.pos.bottom;
        let bottomIn = elementBottom > this.pos.top && elementBottom < this.pos.bottom;
        let percentInView = topIn || bottomIn ? ((bottomIn ? elementBottom : this.pos.bottom) - (topIn ? elementTop : this.pos.top)) / rect.height : 0;
        let centerPercentY = (elementTop - this.pos.top + rect.height / 2) / this.W.h;
        let centerPercentX = (elementLeft - this.pos.left + rect.width / 2) / this.W.w;
        let zeroPoint = {
            top: this.pos.top - rect.height,
            left: this.pos.left - rect.width
        }
        let topPercent = (elementTop - zeroPoint.top) / (this.pos.bottom - zeroPoint.top);
        let leftPercent = (elementLeft - zeroPoint.left) / (this.pos.right - zeroPoint.left);

        return {
            percentInView: this[roundPercent](percentInView),
            percentCenterX: this[roundPercent](centerPercentX),
            percentCenterY: this[roundPercent](centerPercentY),
            percentTop: this[roundPercent](topPercent),
            percentLeft: this[roundPercent](leftPercent),
            rect: rect
        }
    }

    /**
    * Round percentage to a thousandth
    * @return {number} The rounded number
    */
    [roundPercent](v) {
        return (v * 1000 | 0) / 1000
    }

    /**
    * Use the correct `transitionend` event
    * @return {string} The prefixed event name
    */
    [whichTransitionEvent]() {
        const el = document.createElement('div');
        const transitions = {
            'OTransition':'oTransitionEnd',
            'MozTransition':'transitionend',
            'WebkitTransition':'webkitTransitionEnd',
            'transition':'transitionend',
        };

        for(let t in transitions){
            if( el.style[t] !== undefined ){
                return transitions[t];
            }
        }
    }
}


let itemIndex = 0
export const view = Vue.directive('view', {
    unbind: function($el, bind) {
        delete view.items[$el.id]
    },
    inserted: function($el, bind) {
        let id = $el.id || ('view-id-' + itemIndex++)
        let item = viewClass.items[id] || { element: $el, classes: {}, reveal: false, rect: {} }

        if (bind.modifiers.reveal) {
            item.reveal = true
            item.element.classList.add(viewClass.class.reveal)
        }

        if(typeof bind.value === 'number') {
            item.delay = bind.value
        } else if(typeof bind.value === 'function') {
            item.handler = bind.value
        } else if(typeof bind.value === 'object') {
            if(typeof bind.value.handler === 'function') {
                item.handler = bind.value.handler
            }
            if(typeof bind.value.delay === 'number') {
                item.delay = bind.value.delay
            }
        }

        $el.id = id
        item.id = id

        viewClass.observeItem(id, item)
    },
    unbind: function($el, bind) {
        viewClass.unobserveItem($el.id)
    }
})
